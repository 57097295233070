<template>
  <div class="page">
    <div class="jump">
      <img
        style="width: 20px; height: 20px"
        src="@/assets/images/loading.gif"
        alt=""
      />
      <p style="font-size: 14px">正在跳转到支付页面，请稍候~</p>
    </div>
    <form id="pay_form" :action="data.postUrl" method="post" ref="pay_form">
      <input
        v-for="(value, key) in data.postData"
        :key="key"
        type="hidden"
        :name="key"
        :id="key"
        :value="value"
      />
    </form>
  </div>
</template>

<script>
import { Toast } from "vant";
import config from "@/config";
import { getPayOrderInfoById } from "@/api/api";

export default {
  data() {
    return {
      payOrderId: "",
      data: {},
    };
  },
  props: {},
  created() {
    let { payOrderId } = this.$route.query;

    if (payOrderId) {
      this.payOrderId = payOrderId;
      this.getPayOrderInfo();
    }
  },
  mounted() {
    //document.forms["pay_form"].submit();
    document.querySelector("#app").style.height = window.innerHeight + "px";
  },
  computed: {},
  components: {},
  methods: {
    getPayOrderInfo() {
      const that = this;
      getPayOrderInfoById(this.payOrderId)
        .then((res) => {
          console.log(res);
          if (res.payInfo) {
            let obj = JSON.parse(res.payInfo);
            this.data = JSON.parse(obj.payData);
          }

          this.$forceUpdate();
          setTimeout(function () {
            document.forms["pay_form"].submit();
          }, 200);
        })
        .catch((res) => {
          Toast({
            message: res.msg,
            duration: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #f8f9fb;
  .jump {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
